import styled from "styled-components";
import RightImage from "../../user/static/images/home_left_image.png";

const PaperContainer = styled.section`
  overflow: scroll
  width: 55%;
  height: 70%;
  background: ${props => props.theme.primary};

  .left_content {
    width: 45%;
    height: 100%;
    background: url(${RightImage});
    background-size: cover;
  }

  .right_content {
    padding: 10px;
    width: 55%;
    height: 100%;
    font-size: 1.5rem;
    text-align: justify;
    overflow: scroll;
  }

  // flex

  display: flex;

  .left_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .left_content div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .right_content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 475px) {
    width: 95%;
    height: 45%;
    border-radius: 3px;

    .right_content {
      width: 100%;
    }

    .left_content {
      display: none;
    }
  }
`;

export default PaperContainer;
