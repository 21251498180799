import React, { useEffect, useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { response_translate } from '../../../Tools/responseHandler';

//contexts
import AlertContext from '../../../contexts/Alert/AlertContext';

//services
import Api from '../../../service/api';

//components
import Card from '../../../components/Card';
import Chart from '../../../components/Chart';

//styles
import GraphContainer from './styles';
import Loading from '../../../components/Loading';

export default function Graph() {
  const token = Cookies.get('@user');

  const { setAlert } = useContext(AlertContext);

  const [licenses, setLicenses] = useState(0);
  const [claimed, setClaimed] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [graphData, setGraphData] = useState([{ month: 0, claimed: 0 }]);
  const [activeUsers, setActiveUsers] = useState(0);

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true);
    Api.get('/admin/graph', { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
        setGraphData(res.data.claimedMetrics.reverse());
        setLicenses(res.data.availableLicenses || 0);
        setClaimed(res.data.totalUserClaimed || 0);
        setTotalUsers(res.data.totalUsers || 0);
        setActiveUsers(res.data.activeUsers || 0);
        setLoading(false);
      })
      .catch((err) => {
        setAlert({
          type: 'error',
          severity: 'error',
          message: response_translate(err.response.data.message),
          show: true,
        });
      });
  }, [setAlert, token]);

  return (
    <GraphContainer>
      {loading ? <Loading /> : ""}
      <div className="section_title">
        <h2>Relatório geral</h2>
      </div>
      <div className="mini_box_holder">
        <div className="boxes">
          <Card width="24%" height="100%">
            <div className="card_title">Licenças disponíveis</div>
            <div className="card_content">
              <p>{licenses}</p>
            </div>
          </Card>
          <Card width="24%" height="100%">
            <div className="card_title">Usuários Reivindicados</div>
            <div className="card_content">
              <p>{claimed}</p>
            </div>
          </Card>
          <Card width="24%" height="100%">
            <div className="card_title">Usuários cadastrados</div>
            <div className="card_content">
              <p>{totalUsers}</p>
            </div>
          </Card>
          <Card width="24%" height="100%">
            <div className="card_title">Usuários Ativos</div>
            <div className="card_content">
              <p>{activeUsers}</p>
            </div>
          </Card>
        </div>
      </div>
      <div className="section_title">
        <h2>Relatório de uso da plataforma</h2>
      </div>
      <div className="big_box_holder">
        <Card width="100%" height="100%">
          <Chart data={graphData} />
        </Card>
      </div>
    </GraphContainer>
  );
}
