module.exports = {
  response_translate(message, optional_complement) {
    const friendly_messages = {
      "Credentials do not match": "Usuário ou senha incorreto. Por favor, tente novamente.",
      "Name, id and numDoc must be a non-empty string":
        "Nome, ID e numDoc devem obrigatóriamente estar preenchidos!",
      "User already exists in our database": "Usuário já existente.",
      "User created successfully": "Usuário criado com sucesso!",
      "Invalid or expired token":
        "Token de sessão invalido ou expirado! Por favor, refaça o login para gerar um novo.",
      "term created successfully": "Termo criado com sucesso!",
      "service temporally unavaliable, please try again later":
        "Serviço temporariamente indisponível, por favor tente novamente mais tarde",
      "The csv is missing some hearders": "Campos invalidos no csv",
      "No user founded on csv": "Nenhum usuário encontrado no csv",
    };

    return friendly_messages[message] ? friendly_messages[message] : message;
  },
};
