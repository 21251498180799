import axios from 'axios';

const api = () => {
  const defaultOptions = {
    baseURL: `${process.env.REACT_APP_API_URL}/backend`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  const instance = axios.create(defaultOptions);

  return instance;
}

export default api();
