import React, { useContext, useEffect } from 'react';
import { Switch, Redirect, Route, BrowserRouter as Router } from 'react-router-dom';

//contexts
import AuthContext from '../contexts/Auth/AuthContext';
import AdminGlobalProvider from '../contexts/AdminGlobalContext/GlobalContextProvider';
import UserGlobalProvider from '../contexts/UserGlobalContext/UserContextProvider';

//user
import Home from '../user/pages/Home';
import Term from '../user/pages/Term';
import Guide from '../user/pages/Guide';
import Welcome from '../user/sub_pages/Welcome';
import Login from '../user/sub_pages/Login';
import Questions from '../user/sub_pages/Questions';
import WorkplaceTerm from '../user/sub_pages/WorkplaceTerm';
import ClientTerm from '../user/sub_pages/ClientTerm';
import Information from '../user/sub_pages/Information';
import GuideVideo from '../user/sub_pages/GuideVideo';

//admin
import AdminLogin from '../admin/pages/Login';
import Panel from '../admin/pages/Panel';
import GraphPage from '../admin/sub_pages/Graph';
import UsersPage from '../admin/sub_pages/Users';
import BatchPage from '../admin/sub_pages/BatchUser';
import CreateUser from '../admin/sub_pages/CreateUser';
import EditUser from '../admin/sub_pages/EditUser';
import LogPage from '../admin/sub_pages/Logs';
// import IntegrationPage from '../admin/sub_pages/IntegrationPage';
import BugPage from '../admin/sub_pages/Bug';
import SettingsPage from '../admin/sub_pages/Settings';
import api from '../service/api';

export default function Routes() {

    const { userAuth, adminAuth } = useContext(AuthContext);

    useEffect(() => {
        api.get('info/setup').then(res => {
            sessionStorage.setItem('@userTheme', JSON.stringify(res.data));
        });
    });

    const ProtectedRoute = ({ component: Component, path: Path, provider: Provider, authKey, redirect, ...rest }) => (
        <Route Path render={props => (
            authKey === true
            ? <Provider><Component {...props} {...rest} /></Provider>
            : <Redirect to={redirect} />
        )} />
    )

    const Rota = ({ path: Path, component: Component, provider: Provider, ...rest }) => (
        <Route Path render={props => (
            <Provider>
                <Component {...props} {...rest} />
            </Provider>
        )} />
    )

    return (
        <Router>
            <Switch>
                <Rota path="/" exact component={Home} Content={Welcome} provider={UserGlobalProvider} />
                <Rota path="/guide" component={Guide} Content={GuideVideo} provider={UserGlobalProvider} />
                <Rota path="/login/:action" component={Home} Content={Login} provider={UserGlobalProvider} />
                <ProtectedRoute 
                    path="/questions/:action" 
                    component={Home} 
                    Content={Questions} 
                    provider={UserGlobalProvider}
                    authKey={userAuth} 
                    redirect={'/login/signup'}
                /> 
                <ProtectedRoute 
                    path="/term"
                    component={Term}
                    Content={WorkplaceTerm}
                    provider={UserGlobalProvider}
                    authKey={userAuth}
                    sendTo={'/clientTerm'}
                    redirect={'/login/signup'}
                /> 
                <ProtectedRoute
                    path="/clientTerm"
                    component={Term}
                    Content={ClientTerm} 
                    provider={UserGlobalProvider} 
                    authKey={userAuth}
                    sendTo={'/info'}
                    redirect={'/login/signup'}
                /> 
                <ProtectedRoute 
                    path="/info" 
                    component={Home} 
                    Content={Information} 
                    provider={UserGlobalProvider} 
                    authKey={userAuth}
                    redirect={'/login/signup'} 
                />

                <Rota path="/admin" exact component={AdminLogin} provider={AdminGlobalProvider} />
                <ProtectedRoute 
                    path="/admin/panel" exact 
                    component={Panel} 
                    Content={GraphPage} 
                    provider={AdminGlobalProvider} 
                    authKey={adminAuth} 
                    redirect={'/admin'}
                />
                <ProtectedRoute 
                    path="/admin/panel/users" exact 
                    component={Panel} 
                    Content={UsersPage} 
                    provider={AdminGlobalProvider} 
                    authKey={adminAuth}
                    redirect={'/admin'}
                />
                <ProtectedRoute 
                    path="/admin/panel/log" exact 
                    component={Panel} 
                    Content={LogPage} 
                    provider={AdminGlobalProvider} 
                    authKey={adminAuth}
                    redirect={'/admin'} 
                />
                <ProtectedRoute 
                    path="/admin/panel/bug" exact 
                    component={Panel} 
                    Content={BugPage} 
                    provider={AdminGlobalProvider} 
                    authKey={adminAuth} 
                    redirect={'/admin'}
                />
                <ProtectedRoute 
                    path="/admin/panel/edit/single" exact 
                    component={Panel} 
                    Content={EditUser} 
                    provider={AdminGlobalProvider} 
                    authKey={adminAuth}
                    redirect={'/admin'} 
                />
                <ProtectedRoute 
                    path="/admin/panel/create/single" exact 
                    component={Panel} 
                    Content={CreateUser} 
                    provider={AdminGlobalProvider} 
                    authKey={adminAuth}
                    redirect={'/admin'} 
                />
                <ProtectedRoute 
                    path="/admin/panel/create/batch" exact 
                    component={Panel} 
                    Content={BatchPage} 
                    provider={AdminGlobalProvider} 
                    authKey={adminAuth}
                    redirect={'/admin'} 
                />
                <ProtectedRoute 
                    path="/admin/panel/settings" exact 
                    component={Panel} 
                    Content={SettingsPage} 
                    provider={AdminGlobalProvider} 
                    authKey={adminAuth}
                    redirect={'/admin'} 
                />
            </Switch>
        </Router>
    );
}